html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  box-sizing: border-box;
}
.maincontainer {
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: auto;
}

.section1 {
  height: 100%;
  width: 100%;
}
.bg {
  background-image: url("/public/images/sec1bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.yellowline {
  height: 3px;
  width: 100%;
  background-color: #ffcc42;
  /* max-width: 1920px; */
}
.linehandler {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tophandler {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  margin: auto;
}
.top {
  max-width: 1920px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top img {
  height: auto;
  width: 11%;
  transform: translateY(55%);
}
.top button {
  padding: 10px;
  border: none;
  color: white;
  border-radius: 30px;
  background-color: #ff3333;
  font-family: "Poppins", sans-serif;
  font-size: 0.9vw;
  font-weight: 500;
}
.sec1mid {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
}
.sec1midhandler {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec1left {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 7%;
}
.sec1left h1 {
  font-family: "Raleway", sans-serif;
  width: 97%;
  font-size: 2.7vw;
  font-weight: 700;
  color: white;
  line-height: 35px;
  padding: 0;
  margin: 0;
}
.sec1left span {
  font-family: "Raleway", sans-serif;
  font-size: 2.5vw;
  font-weight: 700;
  color: #ffcc42;
}

.sec1left h5 {
  font-family: "Poppins", sans-serif;
  font-size: 0.9vw;
  font-weight: 200;
  color: white;
}
.sec1left img {
  height: auto;
  width: 43%;
}
.sec1right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 5%;
  padding-right: 7%;
}
.sec1right img {
  height: auto;
  width: 60%;
}
@media (min-width: 1200px) {
  .sec1left h1 {
    line-height: 45px;
    width: 100%;
  }
}
@media (min-width: 1920px) {
  .sec1left h1 {
    font-size: 50px;
    font-weight: 700;
    color: white;
    line-height: 54px;
    padding: 0;
    margin: 0;
  }
  .sec1left span {
    font-size: 48px;
  }
  .sec1left h5 {
    font-size: 16.2px;
  }
  .bg {
    background-size: cover;
    background-position: bottom;
  }
}

@media (max-width: 800px) {
  .section1 {
    height: 100%;
    width: 100%;
  }
  .bg {
    background-image: url("/public/images/sec1bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .yellowline {
    height: 3px;
    width: 100%;
    background-color: #ffcc42;
    margin-bottom: 8%;
  }
  .top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 0px;
    padding-top: 3%;
    width: 100%;
  }
  .top img {
    height: auto;
    width: 50px;
    transform: translateY(55%);
  }
  .top button {
    padding: 7px;
    border: none;
    color: white;
    border-radius: 30px;
    background-color: #ff3333;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-weight: 500;
  }
  .sec1mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sec1left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    text-align: center;
  }
  .sec1left h1 {
    font-family: "Raleway", sans-serif;
    width: 80%;
    font-size: 23px;
    font-weight: 700;
    color: white;
    line-height: 30px;
    padding: 0;
    margin: 0;
  }
  .sec1left span {
    font-family: "Raleway", sans-serif;
    font-size: 23px;
    font-weight: 700;
    color: #ffcc42;
  }

  .sec1left h5 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: white;
    width: 80%;
  }
  .sec1left img {
    height: auto;
    width: 60%;
    max-width: 250px;
  }
  .sec1right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-right: 0%;
  }
  .sec1right img {
    height: auto;
    width: 70%;
  }
}
.section2 {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 8%;
  padding-left: 40px;
  /* padding-right: 5%; */
}
.sec2mid {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
}
.sec2left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec2left .fore_img {
  height: auto;
  width: 75%;
  z-index: 1;
}

.sec2left .red_bg_img {
  height: 115%;
  left: 50px;
  width: fit-content;
  position: absolute;
}

.sec2right {
  flex: 1;
}
.sec2right h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: #000;
  width: 80%;
  line-height: 35px;
}
.sec2right h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  width: 70%;
  font-weight: 500;
  color: #343434;
  margin: 0;
  padding: 0;
}
.sec2right h3 {
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  width: 70%;
  font-weight: 700;
  color: #000;
}
.sec2right button {
  padding: 9px 15px;
  border: none;
  color: #fff;
  border-radius: 30px;
  background-color: #ff3333;
  font-family: "Poppins", sans-serif;
  font-size: 0.9vw;
  font-weight: 600;
  width: 30%;
  white-space: nowrap;
  min-width: 150px;
}
@media (min-width: 1200px) {
  .sec2right h1 {
    line-height: 41px;
  }
}
.sec2right button {
  padding: 12px 30px;
  width: 27%;
}
@media (min-width: 1920px) {
  .sec2right h1 {
    font-size: 38px;
    line-height: 45px;
    width: 75%;
  }
  .sec2right h5 {
    font-size: 19.2px;
    width: 70%;
  }
  .sec2right h3 {
    font-size: 19.2px;
    width: 70%;
  }
  .sec2right button {
    width: 27%;
    font-size: 15.36px;
    padding: 15px 30px;
  }
}
@media (max-width: 800px) {
  .section2 {
    height: 100%;
    width: 100%;
    background-color: white;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 0px;
    /* padding-right: 5%; */
  }
  .sec2mid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sec2left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec2left img {
    height: auto;
    width: 80%;
  }
  .sec2right {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .sec2right h1 {
    font-family: "Raleway", sans-serif;
    width: 80%;
    font-size: 23px;
    font-weight: 700;
    color: #000;
    line-height: 35px;
  }
  .sec2right h5 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    width: 80%;
    font-weight: 500;
    color: #343434;
  }
  .sec2right h3 {
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    /* width: 70%; */
    font-weight: 700;
    color: #000;
  }
  .sec2right button {
    padding: 10px 10px;
    border: none;
    color: #fff;
    border-radius: 30px;
    background-color: #ff3333;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    width: 120px;
    font-weight: 600;
  }
}
.section3 {
  height: 100%;
  width: 100%;
  padding-bottom: 5%;
}

.sec3bg {
  background-image: url("/public/images/sec3bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.sec3bg h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: white;
  padding: 4%;
  text-align: center;
}
@media (min-width: 1920px) {
  .sec3bg h1 {
    font-size: 38.4px;
  }
}
@media (max-width: 800px) {
  .section3 {
    height: 100%;
    width: 100%;
    padding-bottom: 5%;
  }
  .sec3bg {
    background-image: url("/public/images/sec3bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .sec3bg h1 {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: white;
    padding: 5%;
    text-align: center;
  }
}

.section4 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8%;
}
.sec4handler {
  max-width: 1920px;
}
.sec4top h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: black;
  text-align: center;
}
.circletop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  transform: translateY(30%);
}
.circle1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 50%;
  border: none;
  padding: 60px;
  height: 22vw;
  width: 22vw;
  border: 1px solid rgb(118, 117, 117);
}
.Cred {
  background-color: #ff3333;
  border: none;
  color: white !important;
}
.Cyellow {
  background-color: #ffcc42;
  border: none;
}

.circle1 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0;
}
.circletop img {
  height: auto;
  width: 18%;
}
.circle1 h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 0;
  width: 90%;
}
.Cred h1 {
  color: white;
}
.Cred h5 {
  color: white;
}
.circlebottom {
  display: flex;
  justify-content: center;
  align-self: center;
}
.circlebottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
}
.circlebottom img {
  height: auto;
  width: 18%;
}

@media screen and (min-width: 1920px) {
  .sec4top h1 {
    font-size: 38.4px;
  }
  .circle h5 {
    font-size: 19.2px;
  }
  .circle h1 {
    font-size: 23px;
  }
  .circle2 h5 {
    font-size: 19.2px;
  }
  .circle2 h1 {
    font-size: 23px;
  }
  .circle1 h5 {
    font-size: 19.2px;
  }
  .circle1 h1 {
    font-size: 23px;
  }
  .circle1 {
    height: 400px;
    width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .circle1 {
    height: 20vw;
    width: 20vw;
    padding: 30px;
  }
  .circletop {
    transform: translateY(30%);
    gap: 70px;
  }
}
@media screen and (max-width: 800px) {
  .section4 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 8%;
  }
  .sec4top h1 {
    font-family: "Raleway", sans-serif;
    font-size: 23px;
    font-weight: 700;
    color: black;
    text-align: center;
  }
  .circletop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    transform: translateY(0%);
  }

  .circle1 {
    padding: 37px;
    height: 200px;
    width: 200px;
    border: 1px solid rgb(118, 117, 117);
  }

  .circle1 h1 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    margin: 0;
    padding: 0;
  }
  .circletop img {
    height: auto;
    width: 20%;
  }
  .circle1 h5 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: #000;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .circlebottom {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  }
  .circlebottom {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-top: 50px;
  }
  .circlebottom img {
    height: auto;
    width: 20%;
  }
  .Cred {
    background-color: #ff3333;
    border: none;
    color: white !important;
  }
  .Cyellow {
    background-color: #ffcc42;
    border: none;
  }
  .Cred h1 {
    color: white;
  }
  .Cred h5 {
    color: white;
  }
}
.section5 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8%;
  /* padding-top: 5%; */
  background: linear-gradient(
    180deg,
    rgba(204, 205, 100, 0.13) 0%,
    rgba(225, 164, 0, 0.13) 100%
  );
}
.sec5handler {
  max-width: 1920px;
  display: flex;
}
.sec5head h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: black;
  text-align: center;
}
.sec5head span {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: #ff3333;
  text-align: center;
}
.sec5left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 9%;
  padding-top: 5%;
}
.sec5content {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
}
.sec5content img {
  width: 25px;
  height: 25px;
  padding-top: 8px;
}
.sec5text {
  display: flex;
}
.sec5text h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  color: #000;
  padding: 0;
  margin: 0;
  width: 100%;
}
.sec5text span {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: 500;
  color: #000;
  line-height: 10px;
}
.sec5right {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: start;
}
.sec5right img {
  height: auto;
  width: 95%;
}
@media (min-width: 1920px) {
  .sec5text h1 {
    font-size: 24.4px;
  }
  .sec5head h1 {
    font-size: 38.4px;
  }
  .sec5head span {
    font-size: 38.4px;
  }
  .sec5text span {
    font-size: 25.4px;
  }
  .sec5right img {
    height: auto;
    width: 95%;
  }
}
@media (max-width: 800px) {
  .section5 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8%;
    background: linear-gradient(
      180deg,
      rgba(204, 205, 100, 0.13) 0%,
      rgba(225, 164, 0, 0.13) 100%
    );
  }
  .sec5handler {
    max-width: 1920px;
    display: flex;
    flex-direction: column;
  }
  .sec5head h1 {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: black;
    text-align: center;
  }
  .sec5head span {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #ff3333;
    text-align: center;
  }
  .sec5left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 9%;
  }
  .sec5content {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  .sec5content img {
    width: 12px;
    height: 12px;
    padding-top: 4px;
  }
  .sec5text {
    display: flex;
  }
  .sec5text h1 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    width: 95%;
  }
  .sec5text span {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  .sec5right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec5right img {
    height: auto;
    width: 100%;
  }
}
.section6 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  padding-top: 5%;

  background: linear-gradient(
    180deg,
    rgba(204, 205, 100, 0.13) 0%,
    rgba(225, 164, 0, 0.13) 100%
  );
}
.sec6handler {
  max-width: 1920px;
  display: flex;
}
.sec6head h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: black;
  text-align: center;
}
.sec6head span {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: #ff3333;
  text-align: center;
}
.sec6right {
  flex: 1;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
}
.sec6left {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
}
.sec6left img {
  height: auto;
  width: 80%;
}
.sec6content {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 25px;
}
.sec6content img {
  width: 25px;
  height: 25px;
  padding-top: 8px;
}
.sec6text {
  display: flex;
}
.sec6text h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: 700;
  color: #000;
  padding: 0;
  margin: 0;
  width: 85%;
}
.sec6text span {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: 500;
  color: #000;
}
@media (min-width: 1920px) {
  .sec6text h1 {
    font-size: 24.4px;
  }
  .sec6head h1 {
    font-size: 38.4px;
  }
  .sec6head span {
    font-size: 38.4px;
  }
  .sec6text span {
    font-size: 25.4px;
  }
  .sec6left img {
    height: auto;
    width: 80%;
  }
}
@media (max-width: 800px) {
  .section6 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
    padding-top: 5%;

    background: linear-gradient(
      180deg,
      rgba(204, 205, 100, 0.13) 0%,
      rgba(225, 164, 0, 0.13) 100%
    );
  }
  .sec6handler {
    max-width: 1920px;
    display: flex;
    flex-direction: column;
  }
  .sec6head h1 {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: black;
    text-align: center;
  }
  .sec6head span {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #ff3333;
    text-align: center;
  }
  .sec6right {
    flex: 1;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    padding-left: 9%;
  }
  .sec6left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
  }
  .sec6left img {
    height: auto;
    width: 80%;
  }
  .sec6content {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  .sec6content img {
    width: 12px;
    height: 12px;
    padding-top: 4px;
  }
  .sec6text {
    display: flex;
  }
  .sec6text h1 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
    width: 95%;
  }
  .sec6text span {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
}
.section7 {
  height: 100%;
  width: 100%;
}
.sec7top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.sec7top h1 {
  font-family: "Raleway", sans-serif;
  font-size: 2vw;
  font-weight: 700;
  color: black;
  margin: 0;
  padding: 0;
  padding-top: 3%;
}
.sec7top h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: #414141;
  margin: 0;
  padding: 0;
  padding-bottom: 3%;
}
.sec7bg {
  background-image: url("/public/images/sec7bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.sec7main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8%;
  padding-bottom: 8%;
}
.sec7mid {
  background: linear-gradient(180deg, #f33 0%, #ffcc42 100%);
  padding: 50px;
  border-radius: 20px;
  width: 45%;
  height: auto;
  max-width: 750px;
}
.sec7white {
  background: rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 10px;
}
.sec7content {
  background-color: white;
  border-radius: 10px;
}
.labels {
  padding-left: 30px;
}
.radio {
  padding-left: 30px;
}

.inputbox label {
  display: flex;
  justify-content: start;
  align-items: start;
  color: var(--Black-color, #120b1c);
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
}
.inputbox input {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Black-color, #120b1c);
  padding: 10px;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  border: none;
  border: 0.4px solid #d8d1d1;
  border-radius: 30px;
  width: 90%;
}
.inputbox {
  margin-bottom: 15px;
}
.inputbox span {
  color: #ff3333;
  font-weight: 500;
  font-size: 18px;
  margin-left: 4px;
}
.checkbox {
  margin-bottom: 10px;
}
.radio h1 {
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  font-weight: 700;
}
.checkbox h1 {
  color: var(--Black-color, #120b1c);
  font-family: "Raleway", sans-serif;
  font-size: 10px;
  font-weight: 700;
}
.checkbox label {
  color: var(--Black-color, #120b1c);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-right: 30px;
  font-weight: 500;
}
.checkbox input {
  accent-color: #ff3333;
}
.sec7btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
.sec7btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border: none;
  color: white;
  border-radius: 30px;
  background-color: #ff3333;
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 500;
  gap: 5px;
  width: 90%;
}
.sec7btn img {
  height: auto;
  width: 25px;
}

@media (max-width: 800px) {
  .section7 {
    height: 100%;
    width: 100%;
  }
  .sec7top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sec7top h1 {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: black;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-top: 3%;
  }
  .sec7top h5 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: #414141;
    text-align: center;
    margin: 0;
    padding: 0;
    padding-bottom: 3%;
  }
  .sec7bg {
    background-image: url("/public/images/sec7bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .sec7main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .sec7mid {
    background: linear-gradient(180deg, #f33 0%, #ffcc42 100%);
    padding: 15px;
    border-radius: 30px;
    width: 90%;
    height: auto;
  }
  .sec7white {
    background: rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 10px;
  }
  .sec7content {
    background-color: white;
    border-radius: 10px;
  }
  .labels {
    padding-left: 15px;
    padding-right: 15px;
  }
  .radio {
    padding-left: 15px;
    padding-right: 15px;
  }

  .inputbox label {
    display: flex;
    justify-content: start;
    align-items: start;
    color: var(--Black-color, #120b1c);
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 33px;
  }
  .inputbox input {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Black-color, #120b1c);
    padding: 9px;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    border: none;
    border: 0.4px solid #d8d1d1;
    border-radius: 20px;
    width: 90%;
  }
  .inputbox span {
    color: #ff3333;
    font-weight: 500;
    font-size: 12px;
    margin-left: 1px;
  }
  .radio h1 {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-weight: 700;
    width: 95%;
  }
  .checkbox h1 {
    color: var(--Black-color, #120b1c);
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-weight: 700;
  }
  .checkbox label {
    color: var(--Black-color, #120b1c);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    margin-right: 5px;
    font-weight: 500;
  }
  .checkbox input {
    accent-color: #ff3333;
  }
  .sec7btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .sec7btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: none;
    color: white;
    border-radius: 30px;
    background-color: #ff3333;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 500;
    gap: 5px;
    width: 90%;
  }
  .sec7btn img {
    height: auto;
    width: 25px;
  }
}
.section8 {
  height: 100%;
  width: 100%;
}
.sec8bg {
  background-image: url("/public/images/sec8bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.sec8handler {
  max-width: 1920px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.sec8content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  gap: 25px;
}
.sec8content img {
  height: auto;
  width: 30%;
}
.sec8flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.sec8flex h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: 500;
  color: rgb(216, 210, 210);
}
.hr {
  height: 40px;
  width: 1px;
  background-color: white;
}
.sec8content h1 {
  font-family: "Poppins", sans-serif;
  font-size: 1.2vw;
  font-weight: 200;
  color: white;
  padding-bottom: 1%;
}
.sec8content h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.3vw;
  font-weight: 200;
  color: white;
  padding-top: 35px;
}
.email {
  display: flex;
  align-items: center;
  background: var(--Color, #fff);
  box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.22);
  padding: 3px;
  border-radius: 49px;
  overflow: hidden;
  justify-content: end;
  width: 90%;
  margin-bottom: 7%;
  max-width: 450px;
}
input {
  background-color: white;
  color: var(--Black-color, #908f92);
  border-radius: 5px;
  flex: 1;
  border: none;
  text-indent: 10px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  outline: none;
  margin-right: 5px;
}
.emailbut {
  font-family: "Montserrat", sans-serif;
  background-color: #ff3333;
  color: white;
  border-radius: 30px;
  border: none;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 400;
}
.sec8logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3%;
}
.sec8logo img {
  height: auto;
  width: 70%;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgb(216, 210, 210);
}
@media (min-width: 1920px) {
  .sec8content h1 {
    font-size: 23.4px;
  }
  .sec8content h2 {
    font-size: 23.4px;
  }
}
@media (max-width: 800px) {
  .section8 {
    height: 100%;
    width: 100%;
  }
  .sec8bg {
    background-image: url("/public/images/sec8bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .sec8content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    gap: 5px;
  }
  .sec8content img {
    height: auto;
    width: 20%;
  }
  .sec8flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .sec8flex h1 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: rgb(216, 210, 210);
  }
  .emailbut {
    font-family: "Montserrat", sans-serif;
    background-color: #ff3333;
    color: white;
    border-radius: 30px;
    border: none;
    padding: 7px 15px;
    font-size: 10px;
    font-weight: 600;
  }
  input {
    font-size: 10px;
    margin-right: 5px;
  }
  .hr {
    height: 1px;
    width: 30px;
    background-color: rgb(216, 210, 210);
  }
  .sec8content h1 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 300;
    color: rgb(216, 210, 210);
  }
  .sec8content h2 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 200;
    color: white;
    padding-top: 5px;
  }

  .sec8logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3%;
  }
  .sec8logo img {
    height: auto;
    width: 37%;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgb(216, 210, 210);
  }
}
